<template>
  <v-container fluid tag="section">
    <base-material-card icon="mdi-cog"> 
      <slot slot="title">Settings</slot>
      <slot slot="rightButtons" v-if="boxOffice.id">
        <toggle-button text="Edit" openIcon="mdi-pencil" @toggle="showEdit" :showing="showEditToggle"/>
      </slot>
      <div v-if="showEditToggle">
        <v-form ref="boxOffice">
          <text-field label="Name" v-model="editBoxOffice.name" :rules="[utils.requiredRule]">
            <slot slot="tooltip">This should be the name of your organisation.<br/>
                  A box office can have an unlimited number of events.<br/>
                  You can change the box office name at anytime.</slot> 
          </text-field>
          <text-field label="Path" v-model="editBoxOffice.path" :rules="[utils.requiredRuleNoSpaces]">
            <slot slot="tooltip">This will form part of the link (url) that your customers will use to buy tickets.<br/>
                  You can use any value and change it later.<br/>
            </slot> 
          </text-field>
          <text-field label="Email" v-model="editBoxOffice.email" :rules="[utils.emailRule]">
            <slot slot="tooltip">Your email for general correspondance.  You can set a seperate email for each event if you like.
            </slot> 
          </text-field>

          <div class="d-flex">
            <v-switch v-model="editBoxOffice.emailNotifications"/>
            <div class="pt-5" style="valign:middle">Email Notifications? 
              <tooltip left>Would you like to receive a copy of your ticket orders by email?
                <br/>You can change this at any time.
              </tooltip>
            </div>
          </div>

          <text-field label="Privacy Policy Link" v-model="editBoxOffice.privacyPolicy" :rules="[privacyPolicyRule]">
            <slot slot="tooltip">If you have a privacy policy on your website, please paste the link in here.  
              <br/>This will be used to let customers know about your privacy policy for their data.
              <br/>If you don't provide a privacy policy, a default one will be generated using the address given below.
            </slot> 
          </text-field>
          <text-field label="Address" v-model="editBoxOffice.address" :rules="[privacyPolicyRule]">
            <slot slot="tooltip">This should be the address of your organisation, <br/>
                  it is only used to generate a Privacy Policy (if you don't paste a link in the field above).<br/>
                  If you are using your own privacy policy, this field can be left blank.</slot> 
          </text-field>
          <stripe class="mb-4" v-model="boxOffice" edit label @connected="connected" @clicked="save"/>
          <mailchimp class="mb-4" v-model="boxOffice" edit label @connected="connected" @clicked="save"/>

          <!--v-row>
            <v-col cols="auto">Discount Codes</v-col>
            <v-col cols="auto">
              <v-row  v-for="(discount, i) in editBoxOffice.discountsArray" :key="i">
                <v-col>
                  <text-field label="Code" v-model="editBoxOffice.discountsArray[i].code">
                    <slot slot="tooltip">If you would like to offer discounts to some of your customers, <br/>
                     please enter a code and a percentage discount here.</slot> 
                  </text-field>
                </v-col>
                <v-col>
                  <text-field :rules="[utils.priceRule]" suffix="%" label="Percent" v-model="editBoxOffice.discountsArray[i].percent">
                    <slot slot="tooltip">Enter the percentage discount here.</slot> 
                  </text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row-->

          <text-field label="Webhook" v-model="editBoxOffice.webhook">
            <slot slot="tooltip">This webhook will be called for every sucesssful order</slot> 
          </text-field>
          
          <v-label>Logo Image
            <tooltip top>
              Your logo will appear above your event picture for all your events and on your Box Office home page
            </tooltip>
          </v-label>
          <br><cld-image v-if="logoImage" :publicId="logoImage" width="110" crop="scale"/>
          <select-image-button class="mb-4" :boxOffice="editBoxOffice" @selectImage="selectLogoImage"/> 
          
          <v-label>Box Office Image
            <tooltip top>
              You can customise your Box Office home page by uploading a picture
            </tooltip>
          </v-label>
          <br><cld-image v-if="boxOfficeImage" :publicId="boxOfficeImage" width="110" crop="scale"/>
          <select-image-button class="mb-4" :boxOffice="editBoxOffice" @selectImage="selectBoxOfficeImage"/> 
          
          <v-label>Background Image
            <tooltip top>
              You can customise your Box Office home page by uploading a background picture
            </tooltip>
          </v-label>
          <br><cld-image v-if="backgroundImage" :publicId="backgroundImage" width="110" crop="scale"/>
          <select-image-button :boxOffice="editBoxOffice" @selectImage="selectBackgroundImage"/> 

          <div class="mt-4">
            <v-label>Colors
              <tooltip right>
                Choose a background and text color for your Box Office home page
              </tooltip>
            </v-label>
          </div>
          <select-colours :backgroundColour="boxOffice.backgroundColour" :textColour="boxOffice.textColour" @selectColours="selectColours"/> 
        </v-form>
        <div class="mt-4 mb-1">
          <v-label>Description
            <tooltip right>
                Enter a description for your Box Office home page
            </tooltip>
          </v-label>
        </div>
        <rich-text-editor v-model="editBoxOffice.description"/>
        <v-row class="mt-5">
          <v-col cols="auto">
            <v-btn color="success" @click="saveAndClose">Save</v-btn>
            <v-btn color="white" @click="showEditToggle = false">Cancel</v-btn>
          </v-col>
          <v-spacer/>
          <v-col cols="auto">
            <v-btn class="mr-0" color="error" @click="deleteBoxOffice">Delete</v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="3">Your Homepage</v-col>
          <v-col cols="9">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <a :href="utils.makeBoxOfficeURL(boxOffice)" target="_blank">{{utils.makeBoxOfficeURL(boxOffice)}}</a>
                <v-icon v-on="on" light class="ml-4" @click.stop.prevent="copyPath">mdi-content-copy</v-icon>
                <input type="hidden" id="pathToCopy" :value="utils.makeBoxOfficeURL(boxOffice)">
              </template>
              <slot>Click to copy URL</slot>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Name</v-col>
          <v-col cols="9">{{boxOffice.name}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Email</v-col>
          <v-col cols="9">{{boxOffice.email}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Email Notifications</v-col>
          <v-col cols="9">{{boxOffice.emailNotifications}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Path</v-col>
          <v-col cols="9">{{boxOffice.path}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Privacy Policy</v-col>
          <v-col v-if="boxOffice.privacyPolicy" cols="9"><a target="_blank" :href="boxOffice.privacyPolicy">{{boxOffice.privacyPolicy}}</a></v-col>
          <v-col v-else cols="9"><v-btn @click="showPrivacyPolicy = true" color="secondary">Privacy Policy</v-btn></v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Address</v-col>
          <v-col cols="9">{{boxOffice.address}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Stripe Account</v-col>
          <v-col cols="9"><stripe v-model="boxOffice" @connected="connected"/></v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Mailchimp Account</v-col>
          <v-col cols="9"><mailchimp v-model="boxOffice" @connected="connected"/></v-col>
        </v-row>
        <!--v-row>
          <v-col cols="3">Discount Codes</v-col>
          <v-col cols="9">
            <v-row  v-for="(discount, i) in boxOffice.discountsArray" :key="i">
              <v-col cols="auto">{{discount.code}}</v-col>
              <v-col cols="auto">{{discount.percent}}%</v-col>
            </v-row>
          </v-col>
        </v-row-->
        <v-row>
          <v-col cols="3">Webhook</v-col>
          <v-col cols="9">{{boxOffice.webhook}}</v-col>
        </v-row>
        <v-row v-if="boxOffice.logoImage">
          <v-col class="pr-0" cols="3">Logo Image</v-col>
          <v-col cols="9"><cld-image :publicId="boxOffice.logoImage" width="110" crop="scale" /></v-col>
        </v-row>
        <v-row v-if="boxOffice.boxOfficeImage">
          <v-col class="pr-0" cols="3">BoxOffice Image</v-col>
          <v-col cols="9"><cld-image :publicId="boxOffice.boxOfficeImage" width="110" crop="scale" /></v-col>
        </v-row>
        <v-row v-if="boxOffice.backgroundImage">
          <v-col class="pr-0" cols="3">Background Image</v-col>
          <v-col cols="9"><cld-image :publicId="boxOffice.backgroundImage" width="110" crop="scale" /></v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Colors</v-col>
          <v-col cols="9"><v-sheet class="text-center text-justify-center" height="100%" :color="boxOffice.backgroundColour" :style="'color:' + boxOffice.textColour"><pre class="pt-0">{{boxOffice.textColour}}</pre></v-sheet></v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">Description<br/>
          <span v-html="boxOffice.description"/></v-col>
        </v-row>
      </div>
    </base-material-card>
  </v-form>
  <privacy-policy v-model="showPrivacyPolicy" :boxOffice="boxOffice"/>

  </v-container>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import Store from '@/services/Store.js';

  export default {
    name: 'BoxOffice',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      Stripe: () => import('./Stripe'),
      Mailchimp: () => import('./Mailchimp'),
      ToggleButton: () => import('./components/ToggleButton'),
      RichTextEditor: () => import('./components/RichTextEditor2'),
      SelectImageButton: () => import('./components/SelectImageButton'),
      SelectColours: () => import('./components/SelectColours'),
      PrivacyPolicy: () => import('../boxOffice/PrivacyPolicy'),
    },  
    
    computed: {
      utils: function () {
        return Utils;
      }
    },

    data () {
      return {
        currentBoxOfficeId: Store.boxOffice.id,
        editBoxOffice: {},
        backgroundImage: null,
        logoImage: null,
        boxOfficeImage: null,
        boxOffice: {},
        user: {},
        showEditToggle: false,
        showPrivacyPolicy: false,
      }
    },

    created() {
      let uri = window.location.search.substring(1); 
      let params = new URLSearchParams(uri);
      var id = params.get("state");
      if (!id) {
        id = this.$route.params.id;
      }
      this.boxOffice.id = id;
      this.load();
    },
    
    methods: {
      
      privacyPolicyRule() {
        if (this.editBoxOffice.privacyPolicy || this.editBoxOffice.address) {
          return true;
        } 
        return 'Please link to a privacy policy, or enter your address.';
      },

      pageIdRule(value) {
        if (!value) {
          return true;
        }
        const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
        if (pattern.test(value.trim())) {
          return true;
        }
        return 'Invalid PageId.';
      },
      
      async load() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice);
        //this.loadDiscounts()
        this.readImages();
      },

      loadDiscounts() {
        this.boxOffice.discountsArray=[];
        if (this.boxOffice.discounts) {
          for (const [key, value] of Object.entries(this.boxOffice.discounts)) {
            this.boxOffice.discountsArray.push({code:key, percent:value})
          }
        }
      },

      async readImages() {
        this.images = await Api.post(this, "Image", "list", this.boxOffice);
      },

      connected(boxOffice) {
        this.boxOffice = boxOffice;
        this.load();
      },

      async save() {
        // this.editBoxOffice.discounts = {}
        // if (this.editBoxOffice.discountsArray) {
        //   for (var i=0; i<this.editBoxOffice.discountsArray.length; i++) {
        //     if (this.editBoxOffice.discountsArray[i].code) {
        //       this.editBoxOffice.discounts[this.editBoxOffice.discountsArray[i].code] = this.editBoxOffice.discountsArray[i].percent
        //     }
        //   }
        // } 
        this.boxOffice = await Api.post(this, "BoxOffice", "update", this.editBoxOffice);
//        this.loadDiscounts()
      },

      async saveAndClose() {
        if (! await Utils.validate(this.$refs.boxOffice) ) {
          return;
        }
        await this.save();
        this.showEditToggle = false;
      },

      async selectColours(backgroundColour, textColour) {
        this.editBoxOffice.textColour = textColour;
        this.editBoxOffice.backgroundColour = backgroundColour;
      },

      uploadOrEditImage(image) {
        var uploader = window.cloudinary.createUploadWidget({
          cloudName: "happytickets", 
          uploadPreset: "x6cvxr8u",
          folder: this.boxOffice.id,
          cropping: true
          }, (error, result) => { 
            if (error) {
              this.$toast.error(" ", error.message, {position: 'bottomCenter', theme: 'dark', backgroundColor : "red"});
            }
            if (result && result.event === "success") {
              this.refreshImages();
            }
          });
        if (image) {
          uploader.open(null, {files: [image.url]});
        } else {
          uploader.open();
        }
      },

      async refreshImages() {
        await new Promise(r => setTimeout(r, 2000));
        this.readImages();
      },

      showEdit(show) {
        if (show) {
          this.editBoxOffice = Utils.jsonCopy(this.boxOffice)
          this.backgroundImage = this.editBoxOffice.backgroundImage
          this.logoImage = this.editBoxOffice.logoImage
          this.boxOfficeImage = this.editBoxOffice.boxOfficeImage
//          if (!this.editBoxOffice.discountsArray) this.editBoxOffice.discountsArray = []
//          this.editBoxOffice.discountsArray.push({code:"",percent:""})
        }
        this.showEditToggle = show;
      },

      selectBackgroundImage(image) {
        this.backgroundImage = image.id;
        this.editBoxOffice.backgroundImage = image.id;
      },

      selectLogoImage(image) {
        this.logoImage = image.id;
        this.editBoxOffice.logoImage = image.id;
      },

      selectBoxOfficeImage(image) {
        this.boxOfficeImage = image.id;
        this.editBoxOffice.boxOfficeImage = image.id;
      },

      descriptionChanged(description) {
        this.editBoxOffice.description = description;
      },

      async deleteBoxOffice() {
        Store.boxOffice.name = null;
        Store.boxOffice.id = null;
        await Api.post(this, "BoxOffice", "delete", this.boxOffice);
        this.$router.push("/dashboard/BoxOffices"); 
      },

      copyPath () {
          let pathToCopy = document.querySelector('#pathToCopy')
          pathToCopy.setAttribute('type', 'text')    // ?? hidden ????
          pathToCopy.select()

          try {
            document.execCommand('copy');
          } catch (err) {
            alert('Sorry, unable to copy');
          }

          /* unselect the range */
          pathToCopy.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
      },
    }
  }

</script>

<style lang="sass">
  #privacypolicy
    a
      color: #000000
</style>
